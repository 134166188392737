import '../../css/common/_block-services.scss';
import '../../css/homepage/blocks/_block-heroZone.scss';
import '../../css/homepage/blocks/_block-algolia.scss';
import '../../css/common/_item-card-testimonial.scss';
import '../../css/common/_block-stocks.scss';
import '../../css/vehicle/_item.scss';
import '../../css/common/_block-avis-clients.scss';
import '../../css/common/_block-experience.scss';
import '../../css/agencies/_item-card-presentation.scss';
import '../../css/agencies/_item-card-contacts.scss';
import '../../css/agencies/_block-presentation.scss';

import BSN from 'bootstrap.native/dist/bootstrap-native';

// import Swiper
import { Swiper, Navigation, Pagination } from "swiper";
// Install modules
Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css';
import '../../css/common/_block-swiper.scss';

import btnMoreInItem from "../components/_btns-item-testimonial";
import btnMoreInItemResponses from "../components/_btns-item-testimonial-responses";

window.addEventListener('DOMContentLoaded', () => {

    // first, we reference the .nav component that holds all tabs
    const myTabs = document.getElementById('trombi');
    // let's give the initialization a JavaScript reference for the "target" option
    if (myTabs) {
        const myTabsCollection = myTabs.getElementsByTagName('A');
        // initialize the component for all items in the collection
        Array.from(myTabsCollection).forEach(
            tab => new BSN.Tab( tab )
        );
    }


    if (document.getElementById('swiper-avis-clients')) {
        try {
            /* SWIPER */
            const swiperAvisClients = new Swiper('#swiper-avis-clients', {
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next-avis',
                    prevEl: '.swiper-button-prev-avis',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    576: {
                        slidesPerView: 1.1,
                    },
                    768: {
                        slidesPerView: 1.1,
                    },
                    950: {
                        slidesPerView: 2.1,
                    },
                    1300: {
                        slidesPerView: 3.1
                    },
                }
            });
        } catch (error) {
            console.log('Il manque le block avec l\'id "#swiper-avis-clients" pour afficher le slider correctement')
        }
    }

    // Affichage des btns "Afficher Plus/Moins" pour les "avis clients"
    btnMoreInItem();
    btnMoreInItemResponses();

});