//BOUTON "AFFICHER PLUS/MOINS"
import btnMoreChangeText from "./_btns-showmore";

export default function btnMoreInItemResponses() {
    // BOUTON "AFFICHER PLUS/MOINS" - items "Reponses"
    const tabTestimonialResponse = document.querySelectorAll('.item-card__testimonial-response');
    if ( tabTestimonialResponse ) {
        tabTestimonialResponse.forEach( item => {
            const btnMoreResponses = item.querySelector('.btn-more');
            const tabResponseTxt = item.querySelectorAll('.item-card__testimonial-response--text')
            if (btnMoreResponses) {
                btnMoreResponses.addEventListener('click', () => {
                    btnMoreResponses.classList.toggle('active');
                    const BtnMoreText = btnMoreResponses.innerText;
                    btnMoreChangeText(BtnMoreText, btnMoreResponses);
                    tabResponseTxt[0].classList.toggle('d-none');
                    tabResponseTxt[1].classList.toggle('d-none');
                });
            }
        }, this);
    }
}